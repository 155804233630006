/* Provide sufficient contrast against white background */
a {
    color: #0366d6;
}

code {
    color: #E01A76;
}

.btn-primary {
    color: #fff;
    background-color: #1b6ec2;
    border-color: #1861ac;
}

.teamfoto {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.auge {
    height: 70px;
    margin-right: 35px;
}

    .auge:hover {
        transform: scaleX(-1);
    }

.shadow {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.carousel img {
    object-fit: contain;
}

.nowrap {
    word-break: normal!important;
}